import {applyMiddleware, createStore} from "redux";
import rootReducer from './reducers'
import Reactotron from '../reactotronConfig';
import thunk from 'redux-thunk';
import promiseMiddleware from 'redux-promise';
import {persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

const persistConfig = {
  key: 'root',
  storage: storage,
  stateReconciler: autoMergeLevel2,
  blacklist: [
    'curation', // because it remembers already aprobed items from storage
  ],
};

const pReducer = persistReducer(persistConfig, rootReducer);

let store = applyMiddleware(thunk, promiseMiddleware)(createStore)(pReducer, Reactotron.createEnhancer());

export default store;
export const persistor = persistStore(store);

// TODO add redux-persist
