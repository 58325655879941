import {LOGIN_ERRORED, LOGIN_REQUESTED, LOGIN_SUCCESSFULL, LOGOUT_REQUESTED} from '../actions/types';

const defaultState: Context = {
  status: null,
  isLoggedIn: false,
  roles: [],
  token: null,
  user: null,
};

/**
 *  The auth redux reducer.
 *
 *  @param {any} state - The current state.
 *  @param {any} action - The action.
 */
export default function reducer(state: any = defaultState, action: any) {
  switch (action.type) {
    case LOGIN_REQUESTED: {
      return {
        ...state,
        status: LOGIN_REQUESTED,
        token: null,
        isLoggedIn: false,
        loading: true,
        user: null
      };
    }
    case LOGIN_SUCCESSFULL: {
      const {token, user} = action;
      return {
        ...state,
        status: LOGIN_SUCCESSFULL,
        token: token,
        loading: false,
        isLoggedIn: true,
        user: user,
      };
    }
    case LOGOUT_REQUESTED: {
      return defaultState;
    }
    case LOGIN_ERRORED: {
      return {
        ...state,
        status: LOGIN_ERRORED,
        token: null,
        isLoggedIn: false,
        loading: false,
        user: null
      };
    }
    default:
      return state;
  }
}
