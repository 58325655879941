import Reactotron from 'reactotron-react-js'
import {reactotronRedux} from 'reactotron-redux'

let reactotron = Reactotron
  .configure({name: 'RTC Backoffice'})
  .use(reactotronRedux());

if (process.env.NODE_ENV !== 'production') Reactotron.connect(); // let's connect!

export default reactotron;


// README
// doc https://github.com/infinitered/reactotron/blob/master/docs/quick-start-react-js.md
/**
 * How to log

 Reactotron.display({
  name: 'ORANGE', //label
  preview: 'Who?',
  value: 'Orange you glad you don\'t know me in real life?', //can be object
  important: true,
  image: 'url',
})

 */
