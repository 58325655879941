import {
  CURATION_CORRECTED_LIST_ERRORED,
  CURATION_CORRECTED_LIST_REQUESTED,
  CURATION_CORRECTED_LIST_RETRIEVED,
  CURATION_CORRECTED_PUBLISH_REQUESTED,
  CURATION_CORRECTED_REJECTION_REQUESTED,
  CURATION_CORRECTED_SKIP_REQUESTED,
  CURATION_EDITED_LIST_ERRORED,
  CURATION_EDITED_LIST_REQUESTED,
  CURATION_EDITED_LIST_RETRIEVED,
  CURATION_EDITED_PUBLISH_REQUESTED,
  CURATION_EDITED_REJECTION_REQUESTED,
  CURATION_EDITED_SKIP_REQUESTED,
  CURATION_LIST_ERRORED,
  CURATION_LIST_REQUESTED,
  CURATION_LIST_RETRIEVED,
  CURATION_NEW_LIST_ERRORED,
  CURATION_NEW_LIST_REQUESTED,
  CURATION_NEW_LIST_RETRIEVED,
  CURATION_NEW_PUBLISH_REQUESTED,
  CURATION_NEW_REJECTION_REQUESTED,
  CURATION_NEW_SKIP_REQUESTED,
  CURATION_PREMIUM_LIST_RETRIEVED,
  CURATION_PREMIUM_REJECTION_REQUESTED,
  CURATION_PUBLISH_REQUESTED,
  CURATION_REJECTION_REQUESTED,
  CURATION_SHOWROOM_LIST_RETRIEVED,
  CURATION_SHOWROOM_REJECTION_REQUESTED,
  CURATION_SKIP_REQUESTED,
} from '../actions/types';
import Reactotron from '../../reactotronConfig';

const defaultState: Context = {
  newItems: [],
  editItems: [],
  correctedItems: [],
  showroomItems: [],
  premiumItems: [],
  status: '',
};

/**
 *  The auth redux reducer.
 *
 *  @param {any} state - The current state.
 *  @param {any} action - The action.
 */
export default function reducer(state: any = defaultState, action: any) {
  switch (action.type) {
    case CURATION_NEW_LIST_REQUESTED: {
      return {
        ...state,
        status: CURATION_NEW_LIST_REQUESTED,
      };
    }
    case CURATION_NEW_LIST_RETRIEVED: {
      const {items} = action;
      return Object.assign({}, state, {
        status: CURATION_NEW_LIST_RETRIEVED,
        newItems: state.newItems.concat(items),
        // newLoading: false,
      });
    }
    case CURATION_NEW_LIST_ERRORED:
      return {
        ...state,
        status: CURATION_NEW_LIST_ERRORED,
      };
    case CURATION_EDITED_LIST_REQUESTED: {
      return {
        ...state,
        status: CURATION_EDITED_LIST_REQUESTED,
      };
    }
    case CURATION_EDITED_LIST_RETRIEVED: {
      const {items} = action;
      return Object.assign({}, state, {
        status: CURATION_EDITED_LIST_RETRIEVED,
        editItems: state.editItems.concat(items),
        // editLoading: false,
      });
    }
    case CURATION_EDITED_LIST_ERRORED:
      return {
        ...state,
        status: CURATION_EDITED_LIST_ERRORED,
      };
    case CURATION_CORRECTED_LIST_REQUESTED: {
      return {
        ...state,
        status: CURATION_CORRECTED_LIST_REQUESTED,
        newItems: [],
      };
    }
    case CURATION_CORRECTED_LIST_RETRIEVED: {
      const {items} = action;
      return Object.assign({}, state, {
        status: CURATION_CORRECTED_LIST_RETRIEVED,
        correctedItems: items,
        // editLoading: false,
      });
    }
    case CURATION_CORRECTED_LIST_ERRORED:
      return {
        ...state,
        status: CURATION_CORRECTED_LIST_ERRORED,
      };
    case CURATION_NEW_REJECTION_REQUESTED:
    case CURATION_NEW_PUBLISH_REQUESTED:
    case CURATION_NEW_SKIP_REQUESTED:
      state.newItems.shift();
      return state;
    case CURATION_EDITED_REJECTION_REQUESTED:
    case CURATION_EDITED_PUBLISH_REQUESTED:
    case CURATION_EDITED_SKIP_REQUESTED:
      state.editItems.shift();
      return state;
    case CURATION_CORRECTED_REJECTION_REQUESTED:
    case CURATION_CORRECTED_PUBLISH_REQUESTED:
    case CURATION_CORRECTED_SKIP_REQUESTED:
      state.correctedItems.shift();
      return state;
    // Generic manipulations of CURATION
    case CURATION_LIST_REQUESTED:
      return {
        ...state,
        status: CURATION_LIST_REQUESTED,
      };
    case CURATION_LIST_ERRORED:
      return {
        ...state,
        status: CURATION_LIST_ERRORED,
      };
    case CURATION_LIST_RETRIEVED: {
      const {items, typeItems} = action;
      let payload = {};
      payload['status'] = CURATION_CORRECTED_LIST_RETRIEVED;
      payload[typeItems] = items;
      return Object.assign({}, state, payload);
    }
    case CURATION_REJECTION_REQUESTED:
    case CURATION_PUBLISH_REQUESTED:
    case CURATION_SKIP_REQUESTED: {
      const {typeItems} = action;
      state[typeItems].shift();
      return state;
    }
    case CURATION_SHOWROOM_LIST_RETRIEVED: {
      const {items} = action;
      return Object.assign({}, state, {
        status: CURATION_CORRECTED_LIST_RETRIEVED,
        showroomItems: state.showroomItems.concat(items),
      });
    }
    case CURATION_SHOWROOM_REJECTION_REQUESTED: {
      const {id} = action;
      let items = [...state.showroomItems];
      let indexOfItem = items.findIndex(each => each.id === id);
      Reactotron.debug(indexOfItem);
      items[indexOfItem].submited = true;
      return Object.assign({}, state, {
        showroomItems: items
      });
    }
    case CURATION_PREMIUM_LIST_RETRIEVED: {
      const {items} = action;
      return Object.assign({}, state, {
        status: CURATION_CORRECTED_LIST_RETRIEVED,
        premiumItems: state.premiumItems.concat(items),
      });
    }
    case CURATION_PREMIUM_REJECTION_REQUESTED: {
      const {id} = action;
      let items = [...state.premiumItems];
      let indexOfItem = items.findIndex(each => each.id === id);
      Reactotron.debug(indexOfItem);
      items[indexOfItem].submited = true;
      return Object.assign({}, state, {
        premiumItems: items
      });
    }
    default:
      return state;
  }
}
