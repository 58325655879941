import React, {Component} from 'react';
import {HashRouter, Redirect, Route, Switch} from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
import {Provider} from "react-redux";
import {PersistGate} from 'redux-persist/lib/integration/react';
import store, {persistor} from './redux/store';
import {LOGIN_SUCCESSFULL} from "./redux/actions/types";
import Spinner from "reactstrap/es/Spinner";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.min.css';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));

class App extends Component {


  PrivateRoute({component: Component, ...rest}) {
    return (
      <Route {...rest} render={(props) => (
        store.auth.status === LOGIN_SUCCESSFULL
          ? <Component {...props} />
          : <Redirect to='/login'/>
      )}/>
    );
  }

  render() {
    return (
      <Provider store={store}>
        <ToastContainer />
        <PersistGate loading={<Spinner/>} persistor={persistor}>
          <HashRouter>
            <React.Suspense fallback={loading()}>
              <Switch>
                <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>}/>
                <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>}/>
                <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>}/>
                <Route path="/" name="Home" render={props => <DefaultLayout {...props}/>}/>
                {/*<PrivateRoute path='/' component={props => <DefaultLayout {...props}/>} />} />*/}
              </Switch>
            </React.Suspense>
          </HashRouter>
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
