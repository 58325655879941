import auth from './auth';
import {combineReducers} from 'redux';
import curation from "./curation";

const rootReducer = combineReducers({
  auth,
  curation,
});

export default rootReducer;
