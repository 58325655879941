// Auth
export const LOGOUT_REQUESTED = 'LOGOUT_REQUESTED';
export const LOGIN_REQUESTED = 'LOGIN_REQUESTED';
export const LOGIN_SUCCESSFULL = 'LOGIN_SUCCESSFULL';
export const LOGIN_ERRORED = 'LOGIN_ERRORED';

// Curation
export const CURATION_NEW_LIST_REQUESTED = 'CURATION_NEW_LIST_REQUESTED';
export const CURATION_NEW_LIST_RETRIEVED = 'CURATION_NEW_LIST_RETRIEVED';
export const CURATION_NEW_LIST_ERRORED = 'CURATION_NEW_LIST_ERRORED';
export const CURATION_NEW_REJECTION_REQUESTED = 'CURATION_NEW_REJECTION_REQUESTED';
export const CURATION_NEW_PUBLISH_REQUESTED = 'CURATION_NEW_PUBLISH_REQUESTED';
export const CURATION_NEW_SKIP_REQUESTED = 'CURATION_NEW_SKIP_REQUESTED';

export const CURATION_EDITED_LIST_REQUESTED = 'CURATION_EDITED_LIST_REQUESTED';
export const CURATION_EDITED_LIST_RETRIEVED = 'CURATION_EDITED_LIST_RETRIEVED';
export const CURATION_EDITED_LIST_ERRORED = 'CURATION_EDITED_LIST_ERRORED';
export const CURATION_EDITED_REJECTION_REQUESTED = 'CURATION_EDITED_REJECTION_REQUESTED';
export const CURATION_EDITED_PUBLISH_REQUESTED = 'CURATION_EDITED_PUBLISH_REQUESTED';
export const CURATION_EDITED_SKIP_REQUESTED = 'CURATION_EDITED_SKIP_REQUESTED';

export const CURATION_CORRECTED_LIST_REQUESTED = 'CURATION_CORRECTED_LIST_REQUESTED';
export const CURATION_CORRECTED_LIST_RETRIEVED = 'CURATION_CORRECTED_LIST_RETRIEVED';
export const CURATION_CORRECTED_LIST_ERRORED = 'CURATION_CORRECTED_LIST_ERRORED';
export const CURATION_CORRECTED_REJECTION_REQUESTED = 'CURATION_CORRECTED_REJECTION_REQUESTED';
export const CURATION_CORRECTED_PUBLISH_REQUESTED = 'CURATION_CORRECTED_PUBLISH_REQUESTED';
export const CURATION_CORRECTED_SKIP_REQUESTED = 'CURATION_CORRECTED_SKIP_REQUESTED';

export const CURATION_SHOWROOM_LIST_RETRIEVED = 'CURATION_SHOWROOM_LIST_RETRIEVED';
export const CURATION_SHOWROOM_REJECTION_REQUESTED = 'CURATION_SHOWROOM_REJECTION_REQUESTED';
export const CURATION_PREMIUM_REJECTION_REQUESTED = 'CURATION_PREMIUM_REJECTION_REQUESTED';

// Generic manipulations of CURATION
export const CURATION_LIST_REQUESTED = 'CURATION_LIST_REQUESTED';
export const CURATION_LIST_ERRORED = 'CURATION_LIST_ERRORED';
export const CURATION_LIST_RETRIEVED = 'CURATION_LIST_RETRIEVED';
export const CURATION_REJECTION_REQUESTED = 'CURATION_REJECTION_REQUESTED';
export const CURATION_PUBLISH_REQUESTED = 'CURATION_PUBLISH_REQUESTED';
export const CURATION_SKIP_REQUESTED = 'CURATION_SKIP_REQUESTED';

export const CURATION_PREMIUM_LIST_RETRIEVED = 'CURATION_PREMIUM_LIST_RETRIEVED';
